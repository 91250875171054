@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 300 800; 
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 300 800; 
  font-style: italic;
}

* {
  font-family: OpenSans, Arial, sans-serif !important;
}